import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import * as fromAuth from '@app/users/store'
import { Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { takeUntil, tap } from 'rxjs/operators'
import * as fromStore from '@app/shared/store'
import * as moment from 'moment'
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import * as fromCoreStore from '@app/core/store'
import { getUser } from '@app/users/store/selectors/auth.selectors'
import { User } from '@app/shared/models'

@Component({
    selector: 'app-dashboard',
    template: `
    <div class="list__container">
        <div class="mr-auto ml-auto flex flex-col" style="width:98%;">
            <app-dashboard-filter
                [filters]="filters"
                [currentUser]="currentUser$ | async"
                [defaultDate]="defaultDate"
                [loading]="loading"
                (setFilter)="setFilter($event)"
            ></app-dashboard-filter>

            @if(type === 'company'){
                <app-dashboard-company
                    [currentUser]="currentUser$ | async"
                    [shop]="shop"
                    [fuelCost]="fuelCost"
                    [totalDriverPay]="totalDriverPay"
                    [claimsCounts]="claimsCounts"
                    [monthlyExpense]="monthlyExpense"
                    [claimsAcceptedAmount]="claimsAcceptedAmount"
                    [totalRevenue]="totalRevenue"
                    [top]="top"
                    [profit]="profit"
                    [setLoading]="loading"
                    [filters]="filters"
                    [claimsAcceptedCounts]="claimsAcceptedCounts"
                ></app-dashboard-company>
            }
        </div>
    </div>
    `,
    styles: [
        `
            @tailwind base;
            @tailwind components;
            @tailwind utilities;
            .list__container {
                display: flex;
                justify-content: center;
                width: 100%;
            }
        `
    ]
})
export class DashboardsComponent implements OnInit, OnDestroy {

    type = 'company'
    destroyed$ = new Subject<boolean>()
    currentUser$: Observable<User>
    filters
    shop
    fuelCost: number
    totalDriverPay
    defaultDate
    claimsCounts
    monthlyExpense
    claimsAcceptedAmount
    urlParams = this.route.snapshot.queryParams
    totalRevenue
    top
    profit
    claimsAcceptedCounts

    loading = false

    constructor(private store: Store<fromStore.State>, private coreStore: Store<fromCoreStore.State>, private cdr: ChangeDetectorRef, private userStore: Store<fromAuth.State>, private router: Router, private route: ActivatedRoute) {
        this.defaultDate = {
            startDate: moment.utc().startOf('month').toISOString(),
            endDate: moment.utc().endOf('month').toISOString(),
            dataRange: 'thisMonth'
        }

        let urlParams = this.route.snapshot.queryParams
        this.store.dispatch(new fromStore.GetDashboardsFilters(urlParams))
        this.router.navigate(
            [`/dashboards`],
            {
                queryParams: {
                    ...this.defaultDate,
                    ...urlParams
                }
            }
        )

    }

    ngOnInit() {
        this.currentUser$ = this.store.select(getUser)

        this.store.dispatch(new fromStore.GetDashboardsSuccess({}))

        this.store.select(fromStore.selectAllFiltersDashboards)
            .pipe(
                takeUntil(this.destroyed$),
                tap(filter => {

                    this.loading = true

                    if (!filter?.startDate && filter?.startDate !== '') {
                        filter.startDate = this.defaultDate.startDate
                    }

                    if (!filter?.endDate && filter?.endDate !== '') {
                        filter.endDate = this.defaultDate.endDate
                    }

                    if (filter?.dataRange !== '' && !filter?.dataRange && filter?.dataRange !== 'default') {
                        filter.dataRange = this.defaultDate.dataRange
                    }

                    if (filter?.dataRange == 'default') {
                        delete filter.dataRange
                    }

                    for (let key in filter) {
                        if (filter[key] === '') {
                            delete filter[key]
                        }
                    }

                    this.filters = filter
                    this.store.dispatch(new fromStore.GetDashboards(filter))
                })
            )
            .subscribe()

        this.store.select(fromStore.selectDashboards)
            .pipe(
                takeUntil(this.destroyed$),
                tap((data: any) => {
                    if (data) {
                        if (Object.keys(data).length) {
                            this.loading = false

                            if (data?.top?.dispatchers?.length) {
                                data.top.dispatchers.sort((a, b) => a.name.localeCompare(b.name))
                            }
                            this.fuelCost = data?.fuelCost
                            this.shop = data?.shop
                            this.totalDriverPay = data?.totalDriverPay
                            this.claimsCounts = data?.claimsCounts
                            this.monthlyExpense = data?.expensesMonthly
                            this.claimsAcceptedAmount = data?.claimsAcceptedAmount
                            this.totalRevenue = data?.totalRevenue
                            this.top = {
                                dispatchers: data?.top?.dispatchers,
                                customers: this.sortObs(data?.top?.customers, 'totalAmount'),
                                drivers: this.sortObs(data?.top?.drivers, 'totalAmount')
                            }
                            this.profit = data?.profit
                            this.claimsAcceptedCounts = data?.claimsAcceptedCounts
                        }

                    }
                })
            )
            .subscribe()

    }

    sortObs(obs, prop) {
        obs.sort(function (a, b) {
            if (a[prop] < b[prop]) {
                return 1
            } else {
                return -1
            }
        })
        return obs
    }

    setFilter(filter) {

        let currentFilter = { ...this.filters, ...filter }

        if (currentFilter.startDate == '') {
            currentFilter.startDate = this.defaultDate?.startDate
            currentFilter.dataRange = 'default'
        }

        if (currentFilter.endDate == '') {
            currentFilter.endDate = this.defaultDate?.endDate
            currentFilter.dataRange = 'default'
        }


        for (let key in currentFilter) {
            if (currentFilter[key] === null || currentFilter[key] === undefined) {
                delete currentFilter[key]
            }
        }

        this.router.navigate(
            [`/dashboards`],
            {
                queryParams: currentFilter
            }
        )

        this.store.dispatch(new fromStore.GetDashboardsFilters(currentFilter))
    }


    ngOnDestroy(): void {
        this.destroyed$.next(true)
        this.destroyed$.complete()
    }
}
